import { GridDot, GridSpace } from './GridSpaces';
import { selectedSound, shuffleArray } from '../utils';

import aboutHover from '../assets/bg/aboutHover.jpg';
import dotMachinesHover from '../assets/bg/dotMachinesHover.jpg';
import filmsHover from '../assets/bg/filmsHover.jpg';
import projectsHover from '../assets/bg/activeProjectsHover.jpg';
import recordingsHover from '../assets/bg/recordingsHover.jpg';
import researchHover from '../assets/bg/researchHover.jpg';
import resourcesHover from '../assets/bg/resourcesHover.jpg';
import { useEffect } from 'react';

// Prefetching bg images for rollovers
const imagesToPrefetch: string[] = [
    aboutHover,
    filmsHover,
    projectsHover,
    recordingsHover,
    researchHover,
    resourcesHover,
];

export const PrefetchHoverImages: React.FC = () => {
    useEffect(() => {
        const prefetchImages = async () => {
            try {
                const promises = imagesToPrefetch.map(async (imageObj) => {
                    const image = new Image();
                    image.src = imageObj; // Access the default export of the imported image
                    await image.decode();
                    // Optionally, you can cache the image in the browser's cache
                });

                await Promise.all(promises);
                console.log('Images prefetched and cached successfully');
            } catch (error) {
                console.error('Error prefetching images:', error);
            }
        };

        prefetchImages();

        // Clean up function
        return () => {
            // Cleanup logic if needed
        };
    }, []);

    return null;
};

// Array of configurations for HomeGridSpace components
const homeGridConfigurations = [
    {
        isHome: true,
        title: 'simon limbrick',
        link: '/about',
        subtitle: 'More about simon',
        hoverBackground: aboutHover,
        sound: selectedSound(),
    },
    {
        isLink: true,
        title: 'projects',
        link: '/projects',
        hoverBackground: projectsHover,
        sound: selectedSound(),
    },
    {
        isLink: true,
        title: 'recordings',
        link: '/recordings',
        hoverBackground: recordingsHover,
        sound: selectedSound(),
    },
    {
        isLink: true,
        title: 'films',
        link: '/films',
        hoverBackground: filmsHover,
        sound: selectedSound(),
    },
    {
        title: 'dot-machines',
        isLink: true,
        mode: 'dark',
        link: '/dot-machines',
        sound: selectedSound(),
        hoverBackground: dotMachinesHover,
    },
    {
        title: 'research',
        isLink: true,
        link: 'http://research.marimbo.com',
        sound: selectedSound(),
        hoverBackground: researchHover,
    },
    {
        title: 'resources',
        isLink: true,
        mode: 'dark',
        link: '/resources',
        sound: selectedSound(),
        hoverBackground: resourcesHover,
    },
    {},
    {},
];

// Generic component to render HomeGridSpace
const HomeGridSpace = ({ config }) => {
    return (
        <GridSpace>
            <GridDot {...config} />
        </GridSpace>
    );
};

const HomeGridSpaces = () => {
    // Shuffle the array of configurations
    const shuffledConfigurations = shuffleArray(homeGridConfigurations);

    return (
        <>
            {shuffledConfigurations.map((config, index) => (
                <HomeGridSpace key={index} config={config} />
            ))}
        </>
    );
};

export default HomeGridSpaces;
