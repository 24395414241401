import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from './styled';

import About from './components/PageAbout';
import Home from './components/PageHome';
import Index from './components/Index';
import PageDotmachine1 from './components/PageDotmachine1';
import PageDotmachines from './components/PageDotmachines';
import PageFilms from './components/PageFilms';
import PageProjects from './components/PageProjects';
import PageRecordings from './components/PageRecordings';
import PageResources from './components/PageResources';
import PageSingleFilm from './components/PageSingleFilm';
import PageSingleProject from './components/PageSingleProject';
import PageSingleRecording from './components/PageSingleRecording';
import Start from './components/PageStart';
import { isMobile } from 'react-device-detect';
import { theme } from './styled/theme';

const GlobalStyles = createGlobalStyle`

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: ${theme.typography.headerFamily};
    font-size: 1em;

    @media only screen and (max-width: ${theme.breakpoint.mobile}) {
        font-size: 0.9em;
        }

    font-weight: 500;
    color: ${theme.color.text};
    background: ${theme.color.background};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga" on, "calt" on;
    overflow-x: hidden;
}
`;

function App() {
    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;800&display=swap"
                rel="stylesheet"
            />
            <script
                async
                defer
                src="https://scripts.withcabin.com/hello.js"
            ></script>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <BrowserRouter>
                    <Routes>
                        <Route path="/home" element={<Home />}></Route>
                        {/* Bypasses unnecessary audio setup screen on mobile */}
                        {isMobile ? (
                            <Route path="/" element={<Home />}></Route>
                        ) : (
                            <Route path="/" element={<Start />}></Route>
                        )}
                        <Route path="/about" element={<About />}></Route>
                        <Route
                            path="/dot-machine-1"
                            element={<PageDotmachine1 />}
                        ></Route>
                        <Route
                            path="/dot-machines"
                            element={<PageDotmachines />}
                        ></Route>
                        <Route
                            path="/projects"
                            element={<PageProjects />}
                        ></Route>
                        <Route
                            path="/projects/:slug"
                            element={<PageSingleProject />}
                        ></Route>
                        <Route
                            path="/recordings"
                            element={<PageRecordings />}
                        ></Route>
                        <Route
                            path="/recordings/:slug"
                            element={<PageSingleRecording />}
                        />
                        <Route path="/films" element={<PageFilms />}></Route>
                        <Route
                            path="/films/:slug"
                            element={<PageSingleFilm />}
                        />
                        <Route
                            path="/resources"
                            element={<PageResources />}
                        ></Route>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
}

export default App;
