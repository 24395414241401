import styled, { css } from '../styled';
import { useCMSArrayData, useCMSPageData } from '../utils';

import { PortableText } from '@portabletext/react';
import TemplateContent from './TemplateContent';
import TemplatePage from './TemplatePage';
import Text from './Text';

export default function PageResources() {
    const pageData = useCMSPageData(
        `*[_type == "pageIntroduction" && pageName == "Resources"] {
                    pageDescription
           }`
    );

    const categoryFileData = useCMSArrayData(
        `*[_type == "resource" && category->title == "File" ] | order(lower(resourceName) asc) {
                    resourceName,
                    resourceDescription,
                    "file": file.asset->url,
           }`
    );

    const categoryScoreData = useCMSArrayData(
        `*[_type == "resource" && category->title == "Score"] | order(lower(resourceName) asc) {
                    resourceName,
                    resourceDescription,
                    "file": file.asset->url,
           }`
    );

    const categoryImageData = useCMSArrayData(
        `*[_type == "resource" && category->title == "Image"] | order(lower(resourceName) asc) {
                    resourceName,
                    resourceDescription,
                    "file": file.asset->url,
           }`
    );

    const categorySoftwareData = useCMSArrayData(
        `*[_type == "resource" && category->title == "Software"] | order(lower(resourceName) asc) {
                    resourceName,
                    resourceDescription,
                    "file": file.asset->url,
           }`
    );

    const ListItem = styled.div`
        ${({ theme }) => css`
            width: 100%;
            display: grid;
            align-items: center;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr;
            gap: ${theme.spacing.xxl};
            padding: 0 0 ${theme.spacing.m} 0;
            margin: 0 0 ${theme.spacing.s} 0;
            border-bottom: 1px dashed ${theme.color.dividerDark};
            &:last-child {
                border: none;
            }
        `}
    `;

    const StyledAnchor = styled.a`
        ${({ theme }) => css`
            width: 100%;
            color: inherit;
            border: 1px solid ${theme.color.primary};
            padding: 2px ${theme.spacing.s};
            border-radius: 2px;
            text-decoration: none;
            &:hover,
            &:focus {
                outline: none;
                color: ${theme.color.white};
                background: ${theme.color.text};
                border: 1px solid ${theme.color.text};
            }
        `}
    `;

    const renderResourceList = (data) =>
        data
            ? data.map((item, index) => (
                  <ListItem key={index}>
                      <span>
                          <Text tabIndex={1}>{item.resourceName}</Text>
                          {item.resourceDescription && (
                              <Text
                                  size="s"
                                  color="textLight"
                                  tabIndex={1}
                                  margin="0"
                              >
                                  <PortableText
                                      value={item.resourceDescription}
                                  />
                              </Text>
                          )}
                      </span>
                      <StyledAnchor href={`${item.file}?dl=`} tabIndex={1}>
                          <Text size="s">Download</Text>
                      </StyledAnchor>
                  </ListItem>
              ))
            : null;

    return (
        <>
            <TemplatePage layout="2col">
                <TemplateContent
                    subtitle="media"
                    title="Resources"
                    layout="flex"
                    sidebarContent={
                        <>
                            {pageData && pageData.pageDescription && (
                                <Text size="s" margin="0 0 xl 0">
                                    <PortableText
                                        value={pageData.pageDescription}
                                    />
                                </Text>
                            )}
                        </>
                    }
                >
                    {categoryScoreData ? (
                        <>
                            <Text color="textLight" margin="0 0 xl 0">
                                scores
                            </Text>
                            {renderResourceList(categoryScoreData)}
                        </>
                    ) : null}
                    {categorySoftwareData ? (
                        <>
                            <Text color="textLight" margin="xl 0 xl 0">
                                software
                            </Text>
                            {renderResourceList(categorySoftwareData)}
                        </>
                    ) : null}
                    {categoryImageData ? (
                        <>
                            <Text color="textLight" margin="xl 0 xl 0">
                                images
                            </Text>
                            {renderResourceList(categoryImageData)}
                        </>
                    ) : null}
                    {categoryFileData ? (
                        <>
                            <Text color="textLight" margin="xxl 0 xl 0">
                                files
                            </Text>
                            {renderResourceList(categoryFileData)}
                        </>
                    ) : null}
                </TemplateContent>
            </TemplatePage>
        </>
    );
}
