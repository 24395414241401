import { urlFor, useCMSPageData } from '../utils';

import Heading from './Heading';
import { PortableText } from '@portabletext/react';
import TemplateContent from './TemplateContent';
import TemplatePage from './TemplatePage';
import Text from './Text';

export default function About() {
    const aboutData = useCMSPageData(`*[_type == "about"] {
        block1,
        quote1,
        block2,
        image1,
        image1credit,
        block3,
        quote2,
        block4,
        quote3,
        block5,
        block6,
        block7,
        block8,
        block9,

}`);

    return (
        <>
            <TemplatePage layout="2col">
                {aboutData ? (
                    <TemplateContent subtitle="about" title="simon limbrick">
                        {aboutData.block1 && (
                            <Text margin="0 0 l 0">
                                <PortableText value={aboutData.block1} />
                            </Text>
                        )}
                        {aboutData.quote1 && (
                            <Heading margin="l xxl xxl xxl">
                                <PortableText value={aboutData.quote1} />
                            </Heading>
                        )}
                        {aboutData.block2 && (
                            <Text margin="0 0 l 0">
                                <PortableText value={aboutData.block2} />
                            </Text>
                        )}
                        {aboutData.image1 && (
                            <>
                                <img
                                    src={
                                        aboutData.image1 &&
                                        urlFor(aboutData.image1).url()
                                    }
                                    width="100%"
                                    alt="simon playing vibraphone"
                                />
                                <Text
                                    size="s"
                                    color="textLight"
                                    margin="s 0 0 0"
                                >
                                    {aboutData.image1credit}
                                </Text>
                            </>
                        )}
                        {aboutData.block3 && (
                            <Text margin="xxl 0 0 0">
                                <PortableText value={aboutData.block3} />
                            </Text>
                        )}
                        {aboutData.quote2 && (
                            <Heading margin="xxl">
                                <PortableText value={aboutData.quote2} />
                            </Heading>
                        )}
                        {aboutData.block4 && (
                            <Text>
                                <PortableText value={aboutData.block4} />
                            </Text>
                        )}
                        {aboutData.quote3 && (
                            <Heading margin="xxl">
                                <PortableText value={aboutData.quote3} />
                            </Heading>
                        )}{' '}
                        {aboutData.block5 && (
                            <Text>
                                <PortableText value={aboutData.block5} />
                            </Text>
                        )}
                        {aboutData.block6 && (
                            <>
                                <Text color="textLight" margin="xl 0 0 0">
                                    ensembles
                                </Text>
                                <Text margin="0 0 xxl 0">
                                    <PortableText value={aboutData.block6} />
                                </Text>
                            </>
                        )}
                        {aboutData.block7 && (
                            <>
                                <Text color="textLight">theatres</Text>
                                <Text margin="0 0 xxl 0">
                                    <PortableText value={aboutData.block7} />
                                </Text>
                            </>
                        )}
                        {aboutData.block8 && (
                            <>
                                <Text color="textLight">dance companies</Text>
                                <Text margin="0 0 xxl 0">
                                    <PortableText value={aboutData.block8} />
                                </Text>
                            </>
                        )}
                        {aboutData.block9 && (
                            <>
                                <Heading margin="l 0 0 0">
                                    what the press have said contd.
                                </Heading>
                                <Text margin="xl 0">
                                    <PortableText value={aboutData.block9} />
                                </Text>
                            </>
                        )}
                    </TemplateContent>
                ) : (
                    <>Loading...</>
                )}
            </TemplatePage>
        </>
    );
}
